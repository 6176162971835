import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'
import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type GalleryItem = {
  img: ImageDataLike
  alt: string
  label: string
  link: string
}

type Props = {
  gallery: GalleryItem[]
}

const Section = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: 20px 0;

  ${({ theme }) => theme.media.lg.min} {
    padding: 40px 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledContainer = styled(Container)``

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    overflow: hidden;
    border-radius: 30px;
  }
`

const TileSingleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.gray300};
  border-radius: 15px;
  transition: 0.25s;

  & > ${Heading} {
    br {
      display: none;
    }
  }

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
    height: 200px;
    margin: 0;
    border-radius: 0;

    & > ${Heading} {
      margin-right: 30px;
      br {
        display: inline-block;
      }
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 250px;
    padding: 20px 40px;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  // Gatsby image wrapper
  div {
    max-height: 100%;
  }
`

const Tiles: React.FC<Props> = ({ gallery }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <StyledContainer>
        <TilesWrapper>
          {gallery?.map(({ img, alt, label, link }) => (
            <TileSingleWrapper key={label} as={Link} to={`/${link}`}>
              <Heading
                as="h6"
                size={lg ? 24 : 30}
                weight={500}
                themecolor={lg ? 'black' : 'primary'}
                dangerouslySetInnerHTML={{ __html: label }}
                margin={lg ? '0' : '24px'}
              />
              <ImgWrapper>
                <LazyImage
                  src={img}
                  alt={alt}
                  objectFit={lg ? 'contain' : 'cover'}
                />
              </ImgWrapper>
            </TileSingleWrapper>
          ))}
        </TilesWrapper>
      </StyledContainer>
    </Section>
  )
}

export default Tiles
