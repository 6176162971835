import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/InteligentneRozwiazania/About'
import Tiles from 'components/layout/Sections/Pages/InteligentneRozwiazania/Tiles'
import InfoWithButton from 'components/layout/InfoWithButton'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Inteligentne rozwiązania',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Inteligentne rozwiązania',
    },
  ],
}

const InteligentneRozwiazaniaPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.inteligentneRozwiazania
  const PAGE_SEO = data?.wpPage?.seo

  const INTELLIGENT_SOLUTIONS_GALLERY = React.useMemo(
    () =>
      PAGE?.intelligentSolutionsGates?.map((gate) => ({
        img: gate?.intelligentSolutionsGatesImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: gate?.intelligentSolutionsGatesImg?.altText,
        label: gate?.intelligentSolutionsGatesType,
        link: `${gate?.intelligentSolutionsGatesUrl}/`,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.intelligentSolutionsImg?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.intelligentSolutionsImg?.localFile?.childImageSharp
            ?.gatsbyImageData
        }
        headingMobile={PAGE?.intelligentSolutionsTitle}
        headingDesktop={PAGE?.intelligentSolutionsTitle}
        breadcrumbs={breadcrumbs}
      />
      <About
        description1={PAGE?.intelligentSolutionsText}
        description1MaxWidth="340px"
        description2Title={PAGE?.intelligentSolutionsTitleRight}
        description2={PAGE?.intelligentSolutionsDescriptionRight}
        description2MaxWidth="820px"
      />
      <Tiles gallery={INTELLIGENT_SOLUTIONS_GALLERY} />
      <InfoWithButton />
    </Layout>
  )
}

export default InteligentneRozwiazaniaPage

export const query = graphql`
  query InteligentneRozwiazaniaPage {
    wpPage(slug: { regex: "/inteligentne-rozwiazania/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      inteligentneRozwiazania {
        fieldGroupName
        intelligentSolutionsDescriptionRight
        intelligentSolutionsText
        intelligentSolutionsTitle
        intelligentSolutionsTitleRight
        intelligentSolutionsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        intelligentSolutionsGates {
          intelligentSolutionsGatesType
          intelligentSolutionsGatesUrl
          intelligentSolutionsGatesImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
